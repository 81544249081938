import React, { useState } from 'react';
import AmazingTable from 'components/amazingTable';
import {formatCurrency} from '_base'
import PricingTeir from './pricingTeir';
import { usePricingTeirs } from 'contexts/pricingTeirs';
import { TableSetupProvider } from 'contexts/tableSetup';
import Modal from 'components/modal';

const teirFormatter = (obj) => {
    let teir = 'Unknown';
    switch (obj.teir) {
    case 0: teir = 'Annualy'; break;
    case 1: teir = 'January-March (Q1)'; break;
    case 2: teir = 'April-June (Q2)'; break;
    case 3: teir = 'July-September (Q3)'; break;
    case 4: teir = 'October-December (Q4)'; break;
    case 5: teir = 'January'; break;
    case 6: teir = 'February'; break;
    case 7: teir = 'March'; break;
    case 8: teir = 'April'; break;
    case 9: teir = 'May'; break;
    case 10: teir = 'June'; break;
    case 11: teir = 'July'; break;
    case 12: teir = 'August'; break;
    case 13: teir = 'September'; break;
    case 14: teir = 'October'; break;
    case 15: teir = 'November'; break;
    case 16: teir = 'December'; break;
    default: break;
    }
    return teir;
}

const cols = [
    {id: 'id', label:'ID', display: false, datum: 'id'},
    {id: 'memberTypeId', label: 'Member Type Id', display: false, datum: 'memberTypeId'},
    {id: 'teir', label:'Teir', display: true, formatter: teirFormatter},
    {id: 'price', label:'Price', display: true, formatter: (obj) => {return formatCurrency(obj.price)}}
];

function PricingTeirs() {
    const { pricingTeirs, loadPricingTeirs, savePricingTeir, deleteTeirs } = usePricingTeirs();
    const [teir, setTeir] = useState(null);
    const [showTeir, setShowTeir] = useState(false);

    const editTeir = (teirData) => {
        setTeir(teirData);
        setShowTeir(true);
    }

    const createTeir = () => {
        let data = {
            teir: 0,
            price: 0
        }
        setTeir(data);
        setShowTeir(true);
    }

    const saveTeir = (data) => {
        savePricingTeir(data);
        setShowTeir(false);
    }

    const removeTeirs = (teirs) => {
        const teirIds = teirs.map(t => t.id);
        deleteTeirs(teirIds);
    }

    return (
        <div className='table-setting'>
            <TableSetupProvider tableName='pricingteirs' defaultColumns={cols}>
                <AmazingTable 
                    rows={pricingTeirs}
                    onRefresh={loadPricingTeirs}
                    onRowClick={editTeir}
                    onCreate={createTeir}
                    onCreateLabel='Add tier'
                    onDelete={removeTeirs} />
            </TableSetupProvider>
            <Modal title='Pricing Teir' showing={showTeir} onHide={() => setShowTeir(false)}>
                <PricingTeir value={teir} onSave={saveTeir} />
            </Modal>
        </div>
    )
}

export default PricingTeirs;
