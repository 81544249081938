import React, {useState} from 'react';
import { publish } from 'pubsub-js';
import { useSettings } from 'contexts/settings';
import { useCore } from 'contexts/core';
import { useMemberTypes } from 'contexts/memberTypes';
import { useEmailTemplates } from 'contexts/emailTemplates';
import Toggle from 'components/toggle';

function TenantSettings() {
    const { runAction } = useCore();
    const { tenant, refreshTenantSettings} = useSettings()
    const { memberTypes } = useMemberTypes();
    const { templates } = useEmailTemplates();
    const [fromEmail, setFromEmail] = useState(tenant.fromEmail);
    const [memberEmail, setMemberEmail] = useState(tenant.memberEmail);
    const [defaultMemberType, setDefaultMemberType] = useState(tenant.defaultMemberType);
    const [storeEmail, setStoreEmail] = useState(tenant.storeEmail);
    const [newMembershipEmail, setNewMembershipEmail] = useState(tenant.newMembershipEmail);
    const [renewMembershipEmail, setRenewMembershipEmail] = useState(tenant.renewMembershipEmail);
    const [renew30ReminderEmail, setRenew30ReminderEmail] = useState(tenant.renew30ReminderEmail);
    const [renew3ReminderEmail, setRenew3ReminderEmail] = useState(tenant.renew3ReminderEmail);
    const [expiredReminderEmail, setExpiredReminderEmail] = useState(tenant.expiredReminderEmail);
    const [alternateIdLabel, setAlternateIdLabel] = useState(tenant.alternateIdLabel);
    const [alternateId, setAlternateId] = useState(tenant.alternateId);
    const [newsletter, setNewsletter] = useState(tenant.newsletter);
    const [enewsletter, setEnewsletter] = useState(tenant.enewsletter);
    const [roster, setRoster] = useState(tenant.roster);

    const save = () => {
        let data = {...tenant, fromEmail, memberEmail, defaultMemberType, storeEmail, 
            newMembershipEmail, renewMembershipEmail, renew30ReminderEmail, renew3ReminderEmail, expiredReminderEmail,
            alternateIdLabel, alternateId, newsletter, enewsletter, roster}
        runAction('update_tenant_settings', data, () => {
            publish('success', 'Settings successfully saved');
            refreshTenantSettings();
        })
    }

    return (
        <div className='settings-panel' style={{padding:'20px 40px'}}>
            <h4>Email</h4>
            <hr/>
            <p>This can be overridden when setting up templates.</p>
            <h5>Default from email address for emails</h5>
            <input type='email' 
                value={fromEmail} 
                onChange={(evt) => setFromEmail(evt.target.value)}
                maxLength='64'/>

            <div className='spacer'></div>
            <h4>Member settings</h4>
            <hr/>
            <p>These are settings that if enabled, the member can see and control.</p>
            <p>These settings will always be visible to you in member management.</p>
            <div className='toggle-setting'>
                <Toggle checked={newsletter} onChange={setNewsletter}/><h5>Mail newsletter</h5>
            </div>
            <div className='toggle-setting'>
                <Toggle checked={enewsletter} onChange={setEnewsletter}/><h5>Email newsletter</h5>
            </div>
            <div className='toggle-setting'>
                <Toggle checked={roster} onChange={setRoster}/><h5>List in directory/Roster</h5>
            </div>
            <div className='toggle-setting'>
                <Toggle checked={alternateId} onChange={setAlternateId}/><h5>Alternate ID</h5>
            </div>

            <div className='spacer'></div>
            <h4>Membership</h4>
            <hr/>
            <h5>New/renew notifications email</h5>
            <input type='email' 
                value={memberEmail} 
                onChange={(evt) => setMemberEmail(evt.target.value)} 
                maxLength='64'/>
            <h5>Default new member type</h5>
            <select value={defaultMemberType} onChange={e => setDefaultMemberType(e.target.value)}>
                <option value='0'>-None-</option>
                { memberTypes.map(t => <option key={t.id} value={t.id}>{t.label}</option>)}
            </select>
            <h5>New member welcome email</h5>
            <select value={newMembershipEmail} onChange={e => setNewMembershipEmail(e.target.value)}>
                <option value='0'>-System generated-</option>
                {templates.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
            </select>
            <h5>Current member renewed successfully email</h5>
            <select value={renewMembershipEmail} onChange={e => setRenewMembershipEmail(e.target.value)}>
                <option value='0'>-None-</option>
                {templates.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
            </select>
            <h5>Membership renewing in 30 days email</h5>
            <select value={renew30ReminderEmail} onChange={e => setRenew30ReminderEmail(e.target.value)}>
                <option value='0'>-System generated-</option>
                {templates.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
            </select>
            <h5>Membership renewing in 3 days email</h5>
            <select value={renew3ReminderEmail} onChange={e => setRenew3ReminderEmail(e.target.value)}>
                <option value='0'>-System generated-</option>
                {templates.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
            </select>
            <h5>Membership expired email</h5>
            <select value={expiredReminderEmail} onChange={e => setExpiredReminderEmail(e.target.value)}>
                <option value='0'>-System generated-</option>
                {templates.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
            </select>
            
            <h5>Alternate ID Label</h5>
            <input type='text' 
                value={alternateIdLabel} 
                onChange={(evt) => setAlternateIdLabel(evt.target.value)} 
                maxLength='64'
                disabled={!alternateId} />

            <div className='spacer'></div>
            <h4>Store</h4>
            <hr/>
            <h5>Purchase notifications</h5>
            <div>
                <input type='text' 
                    value={storeEmail} 
                    onChange={(evt) => setStoreEmail(evt.target.value)}
                    maxLength='64'/></div>
            <div className='button-container'>
                <button onClick={save}>Save</button>
            </div>
        </div>
    )
}

export default TenantSettings;