import React, {useEffect, useState} from 'react';
import CurrencyInput from 'react-currency-input-field';

function PricingTeir({value, onSave}) {
    const [teir, setTeir] = useState(0);
    const [price, setPrice] = useState(10);
    
    useEffect(() => {
        if (value) {
            setTeir(value.teir);
            setPrice(value.price);
        }
    }, [value])

    const save = () => {
        let data = {
            teir: teir,
            price: price
        }
        if (value) {
            data.id = value.id;
        }
        onSave(data);
    }

    return (
        <div>
            <div className='contact-preview'>
				<label>Teir</label>
				<select value={teir} onChange={evt => setTeir(evt.target.value)}>
					<option value='0'>Annually</option>
					<option value='1'>January-March (Q1)</option>
					<option value='2'>April-June (Q2)</option>
					<option value='3'>July-September (Q3)</option>
					<option value='4'>October-December (Q4)</option>
					<option value='5'>January</option>
					<option value='6'>February</option>
					<option value='7'>March</option>
					<option value='8'>April</option>
					<option value='9'>May</option>
					<option value='10'>June</option>
					<option value='11'>July</option>
					<option value='12'>August</option>
					<option value='13'>September</option>
					<option value='14'>October</option>
					<option value='15'>November</option>
					<option value='16'>December</option>
				</select>
				<label>Price</label>
                <CurrencyInput prefix='$' value={price} onValueChange={(value) => setPrice(value)} />
			</div>
			<div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
        </div>
    )
}

export default PricingTeir;