import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const CampaignsContext = React.createContext(null);

function CampaignsProvider({children}) {
    const { runAction } = useCore();
    const [loadingCampaigns, setLoadingCampaigns] = useState(true);
    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        loadCampaigns();
    }, [])

    const loadCampaigns = (search) => {
        setLoadingCampaigns(true);
        setCampaigns([]);
        const filter = search?.filter;
        let data = {
            'filter': filter
        }

        runAction('get_campaigns', data, (response) => {
            setCampaigns(response.campaigns);
            setLoadingCampaigns(false);
        });
    };

    const saveCampaign = (data, callback) => {
        const action = data.id ? 'update_campaign' : 'create_campaign';
        runAction(action, data, (result, error) => {
            if (result) {
                loadCampaigns();
                callback();
            }
        });
    }

    const deleteCampaigns = (ids) => {
        runAction('delete_campaign', { ids }, (result, error) => {
            if (result) {
                loadCampaigns();
            } else {
                throw new Error(error);
            }
        });
    }

    const provider = {
        loadingCampaigns,
        campaigns,
        loadCampaigns,
        saveCampaign,
        deleteCampaigns
    }

    return <CampaignsContext.Provider value={provider}>{children}</CampaignsContext.Provider>
}

function useCampaigns() {
    const context = React.useContext(CampaignsContext);
    if (!context) {
        throw new Error('useCampaigns must be used with a CampaignsProvider');
    }
    return context;
}

export { CampaignsProvider, useCampaigns }