import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Toggle from 'components/toggle';
import ImageManager from './imageManager';
import TicketManager from './ticketManager';
import MakesAndModels from './MakesAndModels';
import { VendorsProvider, Vendors } from './vendors';
import { ScheduleProvider, Schedule } from './Schedule';
import SidePanel from 'components/sidePanel';
import Attendees from './Attendees';
import { useEvent } from 'contexts/event';
import { useEmailTemplates } from 'contexts/emailTemplates';
import { useNavigate } from 'react-router-dom';
import { AttendeesProvider } from 'contexts/attendees';
import { MakesAndModelsProvider } from 'contexts/makesAndModels';
import { EventTicketsProvider } from 'contexts/eventTickets';
import 'style/Longform.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile } from '@fortawesome/pro-regular-svg-icons';
import DateAndTimePicker from 'components/dateAndTimePicker';
import CharacterCounter from '../membership/characterCounter';

function ShowSettings(props) {
    const navigate = useNavigate();
    const venues = [];
    const { updateEvent } = useEvent();
    const { templates } = useEmailTemplates();
    const [event, setEvent] = useState(null);
    const [eventType, setEventType] = useState('0');
    const section1 = useRef(null);
    const section2 = useRef(null);
    const section3 = useRef(null);
    const section4 = useRef(null);
    const section5 = useRef(null);
    const section6 = useRef(null);
    const section7 = useRef(null);
    const section8 = useRef(null);
    const section9 = useRef(null);
    const section10 = useRef(null);
    const section11 = useRef(null);
    const attendeesPanelRef = useRef(null);

    useEffect(() => {
        if (props.event) {
            setEvent({...props.event});
            setEventType(props.event.type);
        }
    }, [props.event])

    const lonformScrollHandler = (e) => {
        // unset all highlights
        document.querySelectorAll('h3').forEach(elem => elem.classList.remove('highlight'));
        // get the scroll top
        const isCarEvent = eventType == 1;
        const scrollTop = e.target.scrollTop;
        try {
            if (scrollTop === section1.current.offsetTop) {
                document.querySelector('h3:nth-of-type(1)').classList.add('highlight');
            } else if (scrollTop > section1.current.offsetTop && scrollTop <= section2.current.offsetTop) {
                document.querySelector('h3:nth-of-type(2)').classList.add('highlight');
            } else if (scrollTop > section2.current.offsetTop && scrollTop <= section3.current.offsetTop) {
                document.querySelector('h3:nth-of-type(3)').classList.add('highlight');
            } else if (isCarEvent && scrollTop > section3.current.offsetTop && scrollTop <= section4.current.offsetTop) {
                document.querySelector('h3:nth-of-type(4)').classList.add('highlight');
            } else if (isCarEvent && scrollTop > section4.current.offsetTop && scrollTop <= section5.current.offsetTop) {
                document.querySelector('h3:nth-of-type(5)').classList.add('highlight');
            } else if (isCarEvent && scrollTop > section5.current.offsetTop && scrollTop <= section6.current.offsetTop) {
                document.querySelector('h3:nth-of-type(6)').classList.add('highlight');
            } else if (isCarEvent && scrollTop > section6.current.offsetTop && scrollTop <= section7.current.offsetTop) {
                document.querySelector('h3:nth-of-type(7)').classList.add('highlight');
            } else if (isCarEvent && scrollTop > section7.current.offsetTop && scrollTop <= section8.current.offsetTop) {
                document.querySelector('h3:nth-of-type(8)').classList.add('highlight');
            } else if (isCarEvent && scrollTop > section8.current.offsetTop && scrollTop <= section9.current.offsetTop) {
                document.querySelector('h3:nth-of-type(9)').classList.add('highlight');
            } else if (isCarEvent && scrollTop > section9.current.offsetTop && scrollTop <= section10.current.offsetTop) {
                document.querySelector('h3:nth-of-type(10').classList.add('highlight');
            } else if (isCarEvent && scrollTop > section10.current.offsetTop && scrollTop <= section11.current.offsetTop) {
                document.querySelector('h3:nth-of-type(11)').classList.add('highlight');
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleChange = (key, value) => {
        let _event = {...event};
        _event[key] = value;
        setEvent(_event);
    }
    
    const imagesChangeHandler = (images) => {
        let _event = {...event};
        _event.images = images;
        setEvent(_event);
    }

    const mapsChangeHandler = (maps) => {
        let _event = {...event};
        _event.maps = maps;
        setEvent(_event);
    }

    const openTickets = () => {
        const path = '/tickets/' + event.id;
        navigate(path);
    }

    const save = () => {
        var data = {
            id: event.id,
            name: event.name,
            type: event.type,
            availability: event.availability,
            startDate: event.startDate,
            endDate: event.endDate,
            venue: event.venue,
            description: event.description,
            schedule: event.schedule,
            vendor_reg: event.vendor_reg,
            vendor_reg_startdate: event.vendor_reg_startdate,
            vendor_reg_enddate: event.vendor_reg_enddate,
            vendor_email: event.vendor_email,
            registration: event.registration,
            reg_startdate: event.reg_startdate,
            reg_enddate: event.reg_enddate,
            reg_price: event.reg_price,
            regMemberDiscount: event.regMemberDiscount,
            reg_email: event.reg_email,
            images: event.images,
            maps: event.maps,
            vendors: event.vendors,
            reg_template: event.reg_template,
            vendor_template: event.vendor_template,
            workerSignup: event.workerSignup,
            workerStartDate: event.workerStartDate,
            workerEndDate: event.workerEndDate,
            workerEmail: event.workerEmail,
            workerTemplate: event.workerTemplate,
            additionalPasses: event.additionalPasses,
            passPrice: event.passPrice,
            votingStart: event.votingStart,
            votingEnd: event.votingEnd,
            votingInstructions: event.votingInstructions,
            typedMakeAndModel: event.typedMakeAndModel,
        };
        updateEvent(data, () => props.onBack());
    }

    const eventTypeChangeHandler = (e) => {
        setEventType(e.target.value);
        handleChange('type', e.target.value);
    }

    return (
        <div className='longform_outer'>
            {event &&
            <>
            <div className='longform_section_links_outer'>
                <div className='longform_section_links_inner'>
                    <h3 className='highlight'><a href='#1'>Event Information</a></h3>
                    <h3><a href='#2'>Media</a></h3>
                    <h3><a href='#3'>Tickets</a></h3>
                    {eventType == 1 &&
                    <>
                        <h3><a href='#4'>Schedule</a>(deprecated)</h3>
                        <h3><a href='#5'>Maps</a>(deprecated)</h3>
                        <h3><a href='#6'>Vendors</a>(deprecated)</h3>
                        <h3><a href='#7'>Makes and Models</a></h3>
                        <h3><a href='#8'>Voting</a></h3>
                        <h3><a href='#9'>Participants Registration</a></h3>
                        <h3><a href='#10'>Vendor Registration</a></h3>
                        <h3><a href='#11'>Worker Signup</a></h3>
                    </>
                    }
                </div>
            </div>
            <div className='longform_sections_outer' onScroll={lonformScrollHandler}>
                <div className='longform_sections_inner'>
                    <section id='1' ref={section1}>
                        <h4>Event Information (Required)</h4>
                        <label>Name</label>
                        <input type='text' value={event.name} onChange={(evt) => handleChange('name', evt.target.value)} maxLength='64' />
                        <label>Type</label>
                        <select value={eventType} onChange={eventTypeChangeHandler}>
                            <option value='0'>Basic (Ticket Sales Only)</option>
                            <option value='1'>Competition Show (Has Voting)</option>
                        </select>
                        <label>Availability</label>
                        <select value={event.availability} onChange={e => handleChange('availability', e.target.value)}>
                            <option value='0'>Open to the public</option>
                            <option value='1'>Members only</option>
                        </select>
                        <label>Begin date</label>
                        <DatePicker selected={moment(event.startDate).toDate()} onChange={(date) => handleChange('startDate', date.getTime())}/>
                        <label>End date</label>
                        <DatePicker selected={moment(event.endDate).toDate()} onChange={(date) => handleChange('endDate', date.getTime())} />
                        <label>Venue (required)</label>
                        <select value={event.venue} onChange={(evt) => handleChange('venue', evt.target.value)}>
                            {
                                venues.map((v) => <option key={v.id} value={v.id}>{v.name}</option>)
                            }
                        </select>
                        <label>Description</label>
                        <textarea
                            defaultValue={event.description} 
                            onChange={(evt) => handleChange('description', evt.target.value)}
                            style={{height:'200px',width:'100%'}} 
                            maxLength='2048'></textarea>
                    </section>

                    <hr/>
                    <section id='2' ref={section2}>
                        <h4>Media (Optional)</h4>
                        <ImageManager images={event.images} onChange={imagesChangeHandler} />
                    </section>

                    <hr/>
                    <section id='3' ref={section3}>
                        <h4>Tickets (Optional)</h4>
                        <EventTicketsProvider eventId={event.id}>
                            <TicketManager />
                        </EventTicketsProvider>
                        <div>
                            <button onClick={openTickets}>View tickets</button>
                        </div>
                    </section>
                    {eventType == 1 &&
                        <>
                            <hr/>
                            <section id='4' ref={section4}>
                                <h4><FontAwesomeIcon icon={faMobile} /> Schedule (optional) - deprecated</h4>
                                <ScheduleProvider jsonStr={event.schedule} onChange={jsonStr => handleChange('schedule', jsonStr)}>
                                    <Schedule />
                                </ScheduleProvider>
                                <button onClick={() => attendeesPanelRef.current.show()}>Send mobile notification</button>
                            </section>
                            <hr/>
                            <section id='5' ref={section5}>
                                <h4><FontAwesomeIcon icon={faMobile} /> Maps (Optional) - deprecated</h4>
                                <p>Use the save button at the bottom of this page to save any changes you make.</p>
                                <ImageManager images={event.maps} onChange={mapsChangeHandler} />
                            </section>

                            <hr/>
                            <section id='6' ref={section6}>
                                <h4><FontAwesomeIcon icon={faMobile} /> Vendors (Optional) - deprecated</h4>
                                <p>Use the save button at the bottom of this page to save any changes you make.</p>
                                <VendorsProvider vendorList={event.vendors} onChange={vendorList => handleChange('vendors', vendorList)}>
                                    <Vendors eventId={event.id} />
                                </VendorsProvider>
                            </section>

                            <hr/>
                            <section id='7' ref={section7}>
                                <h4>Makes and Models (Required)</h4>
                                <label>Allow users to type make and model</label>
                                <Toggle checked={event.typedMakeAndModel} onChange={newVal => handleChange('typedMakeAndModel', newVal)}/>
                                {!event.typedMakeAndModel &&
                                    <MakesAndModelsProvider eventId={event.id}>
                                        <p>Manage the list of makes and models that participants can choose from when registering for the event.</p>
                                        <MakesAndModels />
                                    </MakesAndModelsProvider>
                                }
                            </section>

                            <hr/>
                            <section id='8' ref={section8}>
                                <h4>Voting</h4>
                                <h5>Voters and Balloting</h5>
                                <p>For voters and balloting you have 2 options
                                    <ol>
                                        <li>You print ballots outside of Middletwin and enter the completed ballots by hand in Middletwin.</li>
                                        <li>Use Middletwin to create one time use digital ballots that voters can use from their phones.</li>
                                    </ol>
                                    Both coices can be used independently or together. See the knowledge base document on voting to learn more.
                                </p>
                                <label>Voting Start</label>
                                <DateAndTimePicker value={event.votingStart} onChange={(newStart) => {handleChange('votingStart', newStart)}} />
                                <label>Voting End</label>
                                <DateAndTimePicker value={event.votingEnd} onChange={(newEnd) => {handleChange('votingEnd', newEnd)}} />
                                <label>Voting Instructions</label>
                                <textarea 
                                    style={{width:'100%',height:'100px'}} 
                                    value={event.votingInstructions} 
                                    onChange={e => handleChange('votingInstructions', e.target.value)} 
                                    maxLength='200'></textarea>
                                    <CharacterCounter text={event.votingInstructions} max={220} />
                            </section>

                            <hr/>
                            <section id='9' ref={section9}>
                                <h4>Participants Registration (Optional)</h4>
                                <label>Online Participant Registration</label>
                                <label>Enable</label><Toggle checked={event.registration} 
                                    onChange={newVal => handleChange('registration', newVal)}/>
                                <label>Start Date</label> <DatePicker 
                                    selected={moment(event.reg_startdate).toDate()}
                                    onChange={(date) => handleChange('reg_startdate', date.getTime())} />
                                <label>End Date</label> <DatePicker 
                                    selected={moment(event.reg_enddate).toDate()}
                                    onChange={(date) => handleChange('reg_enddate', date.getTime())} />
                                <label>Price</label> <input type='text' 
                                    value={event.reg_price} 
                                    onChange={(evt) => handleChange('reg_price', evt.target.value)} />
                                <label>Member Discount</label>
                                    <input type='text' 
                                        value={event.regMemberDiscount} 
                                        onChange={(evt) => handleChange('regMemberDiscount', evt.target.value)} />
                                <label>Can purchase additional passes</label><Toggle checked={event.additionalPasses}
                                    onChange={newVal => handleChange('additionalPasses', newVal)} />
                                <label>Pass Price</label>
                                    <input type='number'
                                        value={event.passPrice}
                                        onChange={evt => handleChange('passPrice', evt.target.value)} min='0' step='1' />
                                <label>New Notification Email</label> <input type='text' 
                                    value={event.reg_email}
                                    onChange={(evt) => handleChange('reg_email', evt.target.value)} />
                                <label>Response Template</label>
                                <select 
                                    value={event.reg_template}
                                    onChange={(evt) => handleChange('reg_template', evt.target.value)}>
                                        <option value='0'>None</option>
                                        {templates.map(et => <option value={et.id} key={et.id}>{et.name}</option>)}
                                </select>
                            </section>

                            <hr />
                            <section id='10' ref={section10}>
                                <h4>Vendor Registration (Optional)</h4>
                                <label>Online Vendor Registration</label>
                                <label>Enable</label> <Toggle checked={event.vendor_reg}
                                    onChange={newVal => handleChange('vendor_reg', newVal)} />
                                <label>Start Date</label> <DatePicker 
                                    selected={moment(event.vendor_reg_startdate).toDate()} 
                                    onChange={(date) => handleChange('vendor_reg_startdate', date.getTime())}/>
                                <label>End Date</label> <DatePicker 
                                    selected={moment(event.vendor_reg_enddate).toDate()} 
                                    onChange={(date) => handleChange('vendor_reg_enddate', date.getTime())}/>
                                <label>New Notification Email</label> <input type='text' value={event.vendor_email}
                                    onChange={(evt) => handleChange('vendor_email', evt.target.value)} />
                                <label>Response Template</label>
                                <select 
                                    value={event.vendor_template}
                                    onChange={(evt) => handleChange('vendor_template', evt.target.value)}>
                                        <option value='0'>None</option>
                                        {templates.map(et => <option value={et.id} key={et.id}>{et.name}</option>)}
                                </select>
                            </section>

                            <hr />
                            <section id='11' ref={section11}>
                                <h4>Worker Signup (Optional)</h4>
                                <label>Online Worker Signup</label>
                                <label>Enable</label><Toggle checked={event.workerSignup} 
                                    onChange={newVal => handleChange('workerSignup', newVal)}/>
                                <label>Start Date</label> <DatePicker 
                                    selected={moment(event.workerStartDate).toDate()}
                                    onChange={(date) => handleChange('workerStartDate', date.getTime())} />
                                <label>End Date</label> <DatePicker 
                                    selected={moment(event.workerEndDate).toDate()}
                                    onChange={(date) => handleChange('workerEndDate', date.getTime())} />
                                <label>New Worker Signup Email</label>
                                <input type='text' value={event.workerEmail}
                                    onChange={(evt) => handleChange('workerEmail', evt.target.value)} />
                                <label>Response Template</label>
                                <select 
                                    value={event.workerTemplate}
                                    onChange={(evt) => handleChange('workerTemplate', evt.target.value)}>
                                        <option value='0'>None</option>
                                        {templates.map(et => <option value={et.id} key={et.id}>{et.name}</option>)}
                                </select>
                            </section>
                        </>
                    }
                    
                    <div className='button-container'>
                        <button className='linkButton' onClick={props.onBack}>Cancel</button>
                        <button onClick={save}>Save event</button>
                    </div>
                </div>
            </div>
            <SidePanel title='Attendees' ref={attendeesPanelRef}>
                <AttendeesProvider eventId={event.id}>
                    <Attendees onFinished={() => attendeesPanelRef.current.hide()} />
                </AttendeesProvider>
            </SidePanel>
            </>
            }
        </div>
    )
}

export default ShowSettings;
