import React, { useEffect, useState } from 'react';
import Toggle from 'components/toggle';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import { publish } from 'pubsub-js';
import { useCore } from 'contexts/core';

let selections = [];
let elementBuffer = [];

function VenueDetails({venue, onDone}) {
    let keyCount = 0;
    const { runAction } = useCore();
    const [elements, setElements] = useState([]);
    const [addingElement, setAddingElement] = useState(null);
    const venueMap = React.createRef(null);

    useEffect(() => {
        getElements()
    }, [])

    useEffect(() => {
        console.log('elements:' , elements);
        elementBuffer = [...elements];
    }, [elements])

    const enableKeyEventListeners = () => {
        console.log('enable key listeners');
        window.addEventListener('keydown', keyDownListener, false);
        window.addEventListener('keyup', keyUpListener, false)
    }
    const disableKeyEventListeners = () => {
        console.log('disable key listeners');
        window.removeEventListener('keydown', keyDownListener, false)
        window.removeEventListener('keyup', keyUpListener, false)
    }
    const getElements = () => {
        console.log('getElements');
        let data = {venue: venue.id};
        runAction('get_elements', data, (response) => {
            setElements(response.elements)
            setAddingElement(null);
        });
    }
    const back = () => {
        onDone();
    }
    const save = () => {
        clearSelection();

        let data = {id: venue.id, name: venue.name}
        runAction('update_venue', data, (response) => {
            let elemData = {venue: venue.id, elements: elements};
            runAction('update_elements', elemData, (response) => {
                publish('success', 'Venue saved');
                getElements();
            })
        })
    }
    const addBox = () => {clearSelection();setAddingElement('box')}
    const addLine = () => {clearSelection();setAddingElement('line')}
    const addCircle = () => {clearSelection();setAddingElement('circle')}
    const addText = () => {clearSelection();setAddingElement('text')}
    const mapClick = (evt) => {
        console.log('mapClick')
        if (addingElement) {
            let pos = venueMap.current.getBoundingClientRect();
            let x = (evt.pageX - pos.x) * (1024/ pos.width);
            let y = (evt.pageY - pos.y) * (768/ pos.height);
            let elem = {
                type: addingElement,
                id: Date.now(),
                cost: 0,
                isNew: true,
                venue: venue.id,
                row: '',
                num: 0,
                available: false,
                stroke: '#000',
                fill: addingElement === 'text' ? '#000' : '#FFF',
                label: '',
                x: x,
                y: y,
                w: 10,
                h: 10,
                a: 0
            }
            setAddingElement(null)
            setElements([...elements, elem]);
        } else if (!evt.shiftKey) {
            clearSelection();
        }
    }
    const clearSelection = () => {
        console.log('clearSelection');
        if (selections.length > 0) {
            selections.forEach((s) => s.node.setAttribute('stroke', '#000'))
            selections = [];
        }
    }
    const selectElement = (evt, elem, idx) => {
        console.log('selectElement')
        let node = evt.target;
        if (!evt.shiftKey && selections.length > 0) {
            selections.forEach((s) => s.node.setAttribute('stroke', '#000'))
            selections = [];
        }
        const selection = {node: node, element: elem, index: idx};
        selections.push(selection);
        evt.target.setAttribute('stroke', '#F00');
        evt.preventDefault();
        evt.stopPropagation();
    }
    const keyDownListener = (event) => {
        console.log('keyDown:' + event.key);
        if (selections.length > 0) {
            if (event.metaKey && event.key === 'c') {
                let copies = [];
                selections.forEach((s) => {
                    const copy = {...s.element, id: Date.now(), isNew: true, x:s.element.x + 20, y:s.element.y + 20};
                    copies.push(copy);
                });
                setElements(elements.concat(copies));
                clearSelection(true);
                event.preventDefault();
                event.stopPropagation();
            } else if (event.key === 'Delete' || event.key === 'Backspace') {
                let _elements = [...elements];
                selections.forEach((s) => {
                    _elements.splice(s.index, 1);
                });
                setElements(_elements);
                selections = [];
                event.preventDefault();
                event.stopPropagation();
            } else if (['ArrowUp','ArrowDown','ArrowLeft','ArrowRight'].findIndex(a => a === event.key) !== -1) {
                console.log('this is an arrow key move[numSelections:' + selections.length + ',numElements:' + elementBuffer.length + ']');
                keyCount += 1;
                let inc = Math.floor(keyCount / 10) + 1;
                selections.forEach((s) => {
                    if (event.shiftKey) {
                        switch(event.key) {
                            case 'ArrowUp': s.element.h -= inc; break;
                            case 'ArrowDown': s.element.h += inc; break;
                            case 'ArrowLeft': s.element.w -= inc; break;
                            case 'ArrowRight': s.element.w += inc; break;
                            default: break;
                        }
                    } else if (event.altKey) {
                        switch(event.key) {
                            case 'ArrowLeft': s.element.a -= inc; break;
                            case 'ArrowRight': s.element.a += inc; break;
                            default: break;
                        }
                    } else {
                        switch(event.key) {
                            case 'ArrowUp': s.element.y -= inc; break;
                            case 'ArrowDown': s.element.y += inc; break;
                            case 'ArrowLeft': s.element.x -= inc; break;
                            case 'ArrowRight': s.element.x += inc; break;
                            default: break;
                        }
                    }
                    console.log('updating element at index: ' + s.index + ' to:', s.element);
                    elementBuffer.splice(s.index, 1, s.element);
                });
                setElements(elementBuffer);
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }
    const keyUpListener = (event) => {
        if (selections.length > 0) {
            keyCount = 0;
            event.preventDefault();
        }
    }
    const venueNameChangeHandler = (evt) => {
        let _venue = {...venue};
        _venue.name = evt.target.value;
        //this.setState({venue});
    }
    const elementChangeHandler = (attribute, value) => {
        console.log('elementChangeHandler');
        if (selections.length === 1) {
            selections[0].element[attribute] = value;
            let _elements = [...elements];
            _elements.splice(selections[0].index, 1, selections[0].element);
            setElements(_elements)
        }
    }

    return (
        <div className='contact-details'>
            <div className='contact-settings-panel'>
                <div className='breadcrumb' onClick={back}>
                    <div className='breadcrumb-label'>
                        <FontAwesomeIcon icon={faAngleLeft} /> Venues
                    </div>
                </div>
                <div className='contact-name'>{venue.name}</div>
                <hr/>
                <label>Name</label>
                <input type='text' value={venue.name} onChange={venueNameChangeHandler} maxLength='64' />
                <div className='button-container'>
                    <button onClick={save}>Save</button>
                </div>
            </div>
            <div className='contact-communication-panel'>
                <div style={{fontSize:'20px',height:'30px',margin:'10px'}}>Map</div>
                <div className='settingsSection'>
                    <p>To add a new element to the map, click the &quot;Add&quot; button for the desired element and then click on the map where you 
                    would like the element to be added. The element(s) with the red border are the elements that are currently being worked with. Click on an element
                    to make it the element to work with. Shift-click to add multiple elements to the selection. Move elements around using the arrow keys on your keyboard. 
                    Shift-Arrow to change width and height, ALT-Arrow to rotate. To remove element, select them and use the delete key on your keyboard. When finished, click
                    the &quot;Save&quot; button (left) to save the venue and map.</p>
                    <button onClick={addBox}>Add Box</button>
                    <button onClick={addLine}>Add Line</button>
                    <button onClick={addCircle}>Add Circle</button>
                    <button onClick={addText}>Add Text</button>
                    <div>Current Mode: {addingElement ? `Adding ${addingElement}` : 'Editing'}</div>
                    
                    <div className='mapContainer'>
                        <svg ref={venueMap} version='1.1' viewBox='0,0,1024,768' tabIndex='0'
                            xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' 
                            onClick={mapClick} onFocus={enableKeyEventListeners} onBlur={disableKeyEventListeners}>
                                {elements.map((e, idx) => {
                                    let elem = null;
                                    switch(e.type) {
                                        case 'box': 
                                            elem = <path key={idx} stroke='#000' fill='#FFF' 
                                                d={`M${e.x},${e.y} L${(e.x+e.w)},${e.y} L${(e.x+e.w)},${(e.y+e.h)} L${e.x},${(e.y+e.h)} Z`}
                                                transform={!isNaN(e.a) ? `rotate(${e.a},${e.x},${e.y})` : null} 
                                                onClick={(evt) => selectElement(evt, e, idx)} />
                                            break;
                                        case 'line':
                                            elem = <path key={idx} stroke='#000' strokeWidth='2'
                                                d={`M${e.x},${e.y} L${e.x+e.w},${e.y}`}
                                                transform={!isNaN(e.a) ? `rotate(${e.a},${e.x},${e.y})` : null} 
                                                onClick={(evt) => selectElement(evt, e, idx)} />
                                            break;
                                        case 'circle': 
                                            elem = <circle key={idx} stroke='#000' fill='#FFF' cx={e.x} cy={e.y} r={e.w} 
                                            onClick={(evt) => selectElement(evt, e, idx)} />
                                            break;
                                        case 'text': 
                                            elem = <text key={idx} fill='#000' x={e.x} y={e.y} fontSize='25' 
                                                transform={!isNaN(e.a) ? `rotate(${e.a},${e.x},${e.y})` : null}
                                                onClick={(evt) => selectElement(evt, e, idx)} >{e.label}</text>
                                            break;
                                        case 'image': 
                                            elem = <image key={idx} xlinkHref={e.href} x={e.x} y={e.y} height={e.h} width={e.w} 
                                            onClick={(evt) => selectElement(evt, e, idx)} />
                                            break;
                                        default: break;
                                    }
                                    return elem;
                                })}
                        </svg>
                    </div>
                    {selections.length === 1 && 
                        <div className='contact-settings-panel'>
                            <label>Row</label>
                            <input type='text' 
                                value={selections[0].element.row} 
                                onChange={(evt) => {elementChangeHandler('row', evt.target.value)}} 
                                placeholder='[string]' 
                                maxLength='32'/>
                            <label>Number</label>
                            <input type='text' 
                                value={selections[0].element.num} 
                                onChange={(evt) => elementChangeHandler('num', evt.target.value)} 
                                placeholder='[number]' />
                            <label>Cost</label>
                            <input type='text' 
                                value={selections[0].element.cost}
                                onChange={(evt) => elementChangeHandler('cost', evt.target.value)} 
                                placeholder='[$200.00]' />
                            <label>Available</label> 
                            <Toggle checked={selections[0].element.available} onChange={newVal => elementChangeHandler('available', newVal)}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default VenueDetails;