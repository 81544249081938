import React, { useState } from "react";
import templates from "./baseTemplates.jsx";

import { useAuth } from "contexts/auth";

function NewEmailTemplate(props) {
    const emailTemplates = [
        {
            id: 1,
            version: 2,
            value: "New Membership",
            template: templates.newMembership.html,
            design: templates.newMembership.design,
        },
        {
            id: 2,
            version: 2,
            value: "New File",
            template: templates.newFile.html,
            design: templates.newFile.design,
        },
        {
            id: 3,
            version: 2,
            value: "New Event",
            template: templates.newEvent.html,
            design: templates.newEvent.design,
        },
        {
            id: 4,
            version: 2,
            value: "New Product",
            template: templates.newProduct.html,
            design: templates.newProduct.design,
        },
        {
            id: 5,
            version: 2,
            value: "Membership Renewal",
            template: templates.membershipRenewal.html,
            design: templates.membershipRenewal.design,
        },
        {
            id: 6,
            version: 2,
            value: "General Announcement",
            template: templates.generalAnnouncement.html,
            design: templates.generalAnnouncement.design,
        },
    ];

    const [template, setTemplate] = useState('-');
    const [design, setDesign] = useState('');
    const [value, setValue] = useState('');
    const [name, setName] = useState('');
    const [version, setVersion] = useState('');
    const auth = useAuth();

    const save = () => {
        const settings = JSON.stringify({
            fromName: auth.user.name,
            fromEmail: auth.user.email,
            subject: name,
        });
        const data = {
            name: name,
            trigger: 0,
            version: 2,
            template: template,
            design: design,
            settings: settings,
        };

        if (props.onSave) {
            props.onSave(data);
        }
    };

    const baseTemplateChangeHandler = (evt) => {
        let value = evt.target.value;
        setValue(value);
        if (value != '-') {
            let template = emailTemplates.filter((obj) => obj.value === value);
            setTemplate(template[0].template);
            setDesign(template[0].design);
            setVersion(template[0].version);
            setName(value);
        } else {
            setTemplate('');
            setDesign('');
            setVersion('');
            setName('');
        }
    };

    return (
        <div className='contact-preview'>
            <label>Base template*</label>
            <p>Select the base template for the type of email you would like to create</p>
            <select value={value} onChange={baseTemplateChangeHandler}>
                <option value='-'>-Choose One-</option>
                {emailTemplates.map((e) => (
                    <option key={e.id} value={e.value}>
                        {e.value}
                    </option>
                ))}
            </select>
            <label>Name*</label>
            <input type='text' value={name} onChange={e => setName(e.target.value)} maxLength={64} />
            <div className='buttonsHolder'>
                <button onClick={save} disabled={value === '-'}>Save</button>
            </div>
        </div>
    );
}

export default NewEmailTemplate;
